<div class="mainBG">
    <!-- dskMenuBg -->
    <div class="dskMenuBg d-mobile-none align-self-start" [ngClass]="{ 'collapsible': !expandMenu }">
        <!-- dskMenu -->
         @if (isSignedIn) {
            <div class="dskMenu">
                <div class="d-block">
                    <!-- toggleMenu -->
                    <div class="toggleMenu" (click)="expandMenu = !expandMenu">
                        <span></span>
                    </div>
                    <!-- toggleMenu -->
                    <ul>
                        <li>
                            <a href="javascript:void(0)" routerLink="/home" routerLinkActive="active">
                                <label class="col hideTxt">{{'Home'| translate}}</label>
                                <span><img src="assets/images/menuIcon1.svg" alt=""></span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" routerLink="/on-course" routerLinkActive="active">
                                <label class="col hideTxt">{{'PlayGolf' | translate}}</label>
                                <span><img src="assets/images/playGolf.svg" alt=""></span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" routerLink="/discover" routerLinkActive="active">
                                <label class="col hideTxt">{{'Discover'| translate}}</label>
                                <span><img src="assets/images/menuIcon2.svg" alt=""></span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" (click)="navigateToSettingPage('MyBag')"
                            [class.active]="activeTab === 'MyBag'">
                                <label class="col hideTxt">{{'MyBag' | translate}}</label>
                                <span><img src="assets/images/menuIcon4.svg" alt=""></span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" (click)="navigateToSettingPage('MyPartner')"
                            [class.active]="activeTab === 'MyPartner'">
                                <label class="col hideTxt">{{'My' | translate}} <b>{{'GolfAI' | translate}}</b></label>
                                <span><img src="assets/images/myGolf.svg" alt=""></span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="btmBlck">
                    <!-- <div class="txtArea hideTxt">
                        <h6>Complete your profile <br>+ better your game</h6>
                        <p>+ Choose your caddy<br>
                        + Upload your golf bag</p>
                    </div> -->
                    <div class="profileBg">
                        <span class="profile" (click)="navigateToSettingPage('MySettings')" [class.noImage]="!user?.image">
                            <img *ngIf="user?.image" [src]="user?.image" alt="">
                            <div *ngIf="!user?.image" class="profile-letter">{{user?.name?.substring(0, 2)?.toUpperCase()}}
                            </div>
                        </span>
                        <label (click)="navigateToSettingPage('MySettings')" class="col hideTxt">{{user?.name}}</label>
                    </div>
                </div>
            </div>
         }
        
        <!-- dskMenu -->
    </div>
    <!-- dskMenuBg -->

    <!-- mblSlideBg -->
    <div class="mblSlideBg" [ngClass]="{'active': isMobileMenuActive}" *ngIf="isSignedIn">
        <div class="mblSlideBg__header">
            <img src="assets/images/logoIcon.svg" alt="">
            <span class="closeIcon" (click)="isMobileMenuActive = !isMobileMenuActive">
                <img src="assets/images/closeWhite.svg" alt="">
            </span>
        </div>
        <!-- mblSlideBg__menu -->
        <div class="mblSlideBg__menu">
            <ul>
                <li>
                    <a href="javascript:void(0)" routerLink="/home">
                        <label>{{'Home'| translate}}</label>
                        <img src="assets/images/menuIcon1.svg" alt="">
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" routerLink="/on-course" routerLinkActive="active">
                        <label>{{'PlayGolf' | translate}}</label>
                        <img src="assets/images/playGolf.svg" alt="">
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" routerLink="/discover">
                        <label>{{'Discover' | translate}}</label>
                        <img src="assets/images/menuIcon2.svg" alt="">
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" (click)="navigateToSettingPage('MyBag')">
                        <label>{{'MyBag' | translate}}</label>
                        <img src="assets/images/menuIcon4.svg" alt="">
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" (click)="navigateToSettingPage('MyPartner')">
                        <label>{{'My'| translate}} <b>{{'GolfAI' | translate}}</b></label>
                        <img src="assets/images/myGolf.svg" alt="">
                    </a>
                </li>
            </ul>
            <div class="txtArea">
                <h6>{{'EnhanceYourGame' | translate}} <br>
                    {{'With' | translate}} <span>{{'GolfAI' | translate}}</span></h6>
                <p>
                    + {{'ChooseYourPartner' | translate}}<br>
                    + {{'UploadGolfBag' | translate}}<br>
                    + {{'PartnerComingSoon' | translate}}<br>
                    + {{'BetterYourGame' | translate}}<br>
                </p>
            </div>
        </div>
        <!-- mblSlideBg__menu -->
        <div class="profileBg">
            <span class="profile" (click)="navigateToSettingPage('MySettings')" [class.noImage]="!user?.image">
                <img *ngIf="user?.image" [src]="user?.image" alt="">
                <div *ngIf="!user?.image" class="profile-letter">{{user?.name?.substring(0, 2)?.toUpperCase()}}
                </div>
            </span>
            <label (click)="navigateToSettingPage('MySettings')" class="col">My Settings</label>
        </div>
    </div>
    <!-- mblSlideBg -->

    <!-- mobileMenu -->
    <div class="mobileMenu bottom" *ngIf="isSignedIn && !hideExceptTopMenu">
        <ul>
            <li>
                <a href="javascript:void(0)" routerLink="/home" routerLinkActive="active">
                    <span><img src="assets/images/menuIcon1.svg" alt=""></span>
                    <label>{{'Home' | translate}}</label>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" routerLink="/on-course" routerLinkActive="active">
                    <span><img src="assets/images/playGolf.svg" alt=""></span>
                    <label>{{'PlayGolf' | translate}}</label>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" routerLink="/discover" routerLinkActive="active">
                    <span><img src="assets/images/menuIcon2.svg" alt=""></span>
                    <label>{{'Discover' | translate}}</label>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" (click)="navigateToSettingPage('MyBag')"
                    [class.active]="activeTab === 'MyBag'">
                    <span><img src="assets/images/menuIcon4.svg" alt=""></span>
                    <label>{{'MyBag' | translate}}</label>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" (click)="navigateToSettingPage('MyPartner')"
                    [class.active]="activeTab === 'MyPartner'">
                    <span><img src="assets/images/myGolf.svg" alt=""></span>
                    <label>{{'My' | translate}} <b>{{'GolfAI' | translate}}</b></label>
                </a>
            </li>
        </ul>
    </div>
    <!-- mobileMenu -->

    <div class="container" [class.hide-header]="isSignedIn">
        <!-- topMenu -->
            <div class="topMenu" *ngIf="isSignedIn">
                <div class="logoBg">
                    <a href="javascript:void(0)" routerLink="/home" class="d-none d-md-block">
                        <img src="assets/images/logoIcon.svg" alt="Golf AI" class="d-none d-md-block">
                        <img src="assets/images/mblLogo.svg" alt="Golf AI" class="d-md-none">
                    </a>
                    <span (click)="navigateToSettingPage('MySettings')" class="d-md-none">
                        <!-- {{user?.name?.substring(0, 2)?.toUpperCase()}} -->
                        <img *ngIf="user?.image" [src]="user?.image" alt="">
                        <div *ngIf="!user?.image">{{user?.name?.substring(0, 2)?.toUpperCase()}}</div>
                    </span>
                </div>
                <div class="mainLogo signed-logo">
                    <img src="assets/images/logo.svg" alt="Golf AI" class="">
                </div>
                <div class="d-inline-flex">
                    <a href="javascript:void(0)" class="mblIcon d-md-none d-inline-block"
                        (click)="isMobileMenuActive = !isMobileMenuActive">
                        <img src="assets/images/hamBugerIcon.svg" alt="">
                    </a>
                </div>
            </div>
            <div class="topMenu sign-in-hide" *ngIf="!isSignedIn">
                <div class="logoBg">
                    <a href="">
                        <!-- <img src="assets/images/logoIcon.svg" alt="Golf AI" class="d-none d-md-block"> -->
                        <img src="assets/images/logoGolf.svg" alt="Golf AI" class="d-none d-md-block">
                        <img src="assets/images/mblLogo.svg" alt="Golf AI" class="d-md-none">
                    </a>
                </div>
                <!-- <div class="mainLogo d-none d-md-block">
                    <img src="assets/images/logo.svg" alt="Golf AI">
                </div> -->
                <!-- menuBlock -->
            <ul class="menuBlock d-none d-xl-flex mx-2">
                <li>
                    <a routerLink="" class="default" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <div class="position-relative homeIcons">
                            <img src="assets/images/homeIcon.svg" alt="" class="gray">
                            <img src="assets/images/homeIconWhite.png" alt="" class="white">
                        </div>
                        Home
                    </a>
                </li>
                <li>
                    <a routerLink="/play-golf" class="default" routerLinkActive="active">
                        <div class="position-relative playIcons">
                            <img src="assets/images/playGolfIconGray.svg" alt="" class="gray">
                            <img src="assets/images/playGolfIcon1.svg" alt="" class="white">
                        </div>
                        Play Golf
                    </a>
                </li>
                <li>
                    <a routerLink="/discover/pgatour" class="pga" routerLinkActive="active">
                        <span>PGA</span>
                        PGA Tour
                    </a>
                </li>
                <li>
                    <a routerLink="/discover/liv" class="liv" routerLinkActive="active">
                        <span>LIV</span>
                        LIV Golf
                    </a>
                </li>
                <li>
                    <a routerLink="/discover/owgr" class="owgr" routerLinkActive="active">
                        <span>OWGR</span>
                        Rankings
                    </a>
                </li>
            </ul>
            <!-- menuBlock -->
                <div class="d-inline-flex right-menu">
                    <a (click)="openAuthModal(authModal, 'login')" class="smlBtn d-md-block">Login</a>
                    <a (click)="openAuthModal(authModal, 'register')" class="smlBtn dark d-none d-md-block">Join For Free</a>
                    <a (click)="openAuthModal(authModal, 'register')" class="smlBtn dark d-md-none">Join</a>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <ul class="menuBlock d-flex d-xl-none mt-4 mobile-tab-menu" *ngIf="!isSignedIn">
                    <li>
                        <a routerLink="/" class="default" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                            <div class="position-relative homeIcons">
                                <img src="assets/images/homeIcon.svg" alt="" class="gray">
                                <img src="assets/images/homeIconWhite.png" alt="" class="white">
                            </div>
                            Home
                        </a>
                    </li>
                    <li>
                        <a routerLink="/play-golf" class="default" routerLinkActive="active">
                            <div class="position-relative playIcons">
                                <img src="assets/images/playGolfIconGray.svg" alt="" class="gray">
                                <img src="assets/images/playGolfIcon1.svg" alt="" class="white">
                            </div>
                            Play Golf
                        </a>
                    </li>
                    <li>
                        <a routerLink="/discover/pgatour" class="pga" routerLinkActive="active">
                            <span>PGA</span>
                            PGA Tour
                        </a>
                    </li>
                    <li>
                        <a routerLink="/discover/liv" class="liv" routerLinkActive="active">
                            <span>LIV</span>
                            LIV Golf
                        </a>
                    </li>
                    <li>
                        <a routerLink="/discover/owgr" class="owgr" routerLinkActive="active">
                            <span>OWGR</span>
                            Rankings
                        </a>
                    </li>
                </ul>
            </div>
            
            <!-- menuBlock -->        
        <!-- topMenu -->
        <router-outlet></router-outlet>
        <!-- footer -->
        @if (!hideExceptTopMenu) {    
            <div class="footer">
                <div class="d-flex left">
                    <img src="assets/images/logoBlack.svg" alt="">
                </div>
                <div class="right d-flex flex-column">
                    <ul class="order-md-2">
                        <li>
                            <!-- <a routerLink="/privacy-policy">Privacy Policy</a> -->
                            <a href="/assets/files/Golf.ai-Privacy-Policy.pdf" target="_blank">{{'PrivacyPolicy' |
                                translate}}</a>
                        </li>
                        <li>
                            <!-- <a routerLink="/terms-of-service">Terms & Conditions</a> -->
                            <a href="/assets/files/Golf.ai-Terms-of-Service.pdf" target="_blank">{{'TermsAndCondition' |
                                translate}}</a>
                        </li>
                        <li>
                            <a href="mailto:contact@golf.ai">{{'support@golf.ai'}}</a>
                        </li>
                    </ul>
                    <p>{{'CopyrightText' | translate}}</p>
                </div>
            </div>
            <div class="disclaimerBox">
                <div class="d-block">
                    <p>Disclaimer: Golf.ai has no affiliation with the PGA Tour, LIV Golf and the OWGR. All information is generated by AI and may contain inaccuracies. Golf.ai assumes no responsibility for decisions made based on the content provided.</p>
                    <ul class="socialBox">
                        <li>
                            <a target="_blank" href="https://www.instagram.com/golf.ai_/"><img src="assets/images/instagramIcon.svg" alt=""></a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.facebook.com/people/Golfai/61572434076025/"><img src="assets/images/facebookIcon.svg" alt=""></a>
                        </li>
                        <li>
                            <a target="_blank" href="http://linkedin.com/company/golf-ai/"><img src="assets/images/linkedinIco.svg" alt=""></a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.tiktok.com/@golf.ai_"><img src="assets/images/tiktokIcon.svg" alt=""></a>
                        </li>
                        <!-- <li>
                            <a href=""><img src="assets/images/youtubeIcon.svg" alt=""></a>
                        </li>
                        <li>
                            <a href=""><img src="assets/images/xIcon.svg" alt="" class="xIcon"></a>
                        </li> -->
                    </ul>
                </div>
            </div>
        }
        
        <!-- footer -->
    </div>
</div>

<ng-template #authModal>
    <app-auth-modal 
      [inEmbeddedBrowser]="inEmbeddedBrowser"
      [page]="authPage"
      [loadCaddy]="loadCaddyData"
      (closeModal)="closeAuthModal($event)">
    </app-auth-modal>
</ng-template>