import { HttpErrorResponse, HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, throwError } from "rxjs";
import { ToasterService } from "../shared/services/toaster.service";
import { TokenService } from "../auth/token.service";
import { isPlatformBrowser } from "@angular/common";
import {PLATFORM_ID} from '@angular/core';
import { SharedService } from "../shared/services/shared.service";

export const ErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);
  const toaster = inject(ToasterService);
  const tokenService = inject(TokenService);
  const platformId = inject(PLATFORM_ID);
  const sharedService = inject(SharedService);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      let errorMessage = '';

      if (isPlatformBrowser(platformId) && error.error instanceof ErrorEvent) {
        // Client-side errors
        errorMessage = `${error.error.message}`;
      } else if(isPlatformBrowser(platformId)) {
        // Server-side errors
        errorMessage = `${error.status}: ${error.error.message}`;

        // Handle specific HTTP status codes
        if (error.status === 401) {
          // Unauthorized error, redirect to login
          tokenService.removeTokens();
          if(error.error.message && (error.error.message.includes('User is not registered') || error.error.message.includes('Invalid Username or Password'))){
            // User is not registered or wrong credentials
          } else {
            if (router.url !== '/') {
              sharedService.loading.set(true);
              router.navigate(['/']);
              // location.reload();
            }
            errorMessage = 'Unauthorized access. Please login.';
          }
        } else if (error.status === 403) {
          errorMessage = 'Forbidden: Access denied.';
        } else if (error.status === 404) {
          errorMessage = error.error?.message ? error.error.message : 'Resource not found.';
        }
      }

      // Display error in the toaster, regardless of platform
      if (errorMessage && isPlatformBrowser(platformId)) {
        toaster.error(errorMessage,"Error");
      }

      // Return an observable with a user-facing error message
      return throwError(() => new Error(errorMessage));
    })
  );
};
